import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpContactMerci from "../blocs/ContactMerci"
import KdpAriane from "../blocs/Ariane"

const ariane = [
  { name: 'Nous contacter', href: '', current: true },
]

export default function Contact() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Contacter CEOS Avocats</title>
        <meta name="description" content="Votre demande a bien été enregistrée et sera traitée dans les meilleurs délais." />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        <KdpContactMerci />
      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}

