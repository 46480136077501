import React from "react"
export default function Example() {
    return (
      <div className="relative py-16 bg-gray-50 overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
        
          <div className="text-lg max-w-prose mx-auto">
            
            <h1>
              <span className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Merci pour votre demande.
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-orange prose-lg text-gray-500 mx-auto text-justify">
            <p className="text-center">
              <a href="/">Retour à l'accueil</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
  